import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';
import { configuration } from '../configuration/configuration';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ConfigurationModule } from '@nutt/configuration';
import { ErrorInterceptor, TokenInterceptor } from '@nutt/core';

registerLocaleData(localeNl, 'nl');

export const routes: Route[] = [
  { path: '', loadChildren: () => import('@nutt/application-shell').then((m) => m.ApplicationShellModule) },
];
@NgModule({
  declarations: [AppComponent],
  imports: [
    ConfigurationModule.configure(environment, configuration),
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
})
export class AppModule {}
