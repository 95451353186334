import { AssignmentLeaveTypeEnum, ConfigurationInterface, FeatureEnum, FeatureFlagEnum } from '@nutt/configuration';

export const configuration: Readonly<ConfigurationInterface> = {
  tenant: { brand: 'Loohuis', logoUrl: 'assets/logo2.svg' },

  branches: [
    { name: 'Advies', value: 'ADV' },
    { name: 'Almelo', value: 'ALM' },
    { name: 'Apeldoorn', value: 'APE' },
    { name: 'Beveiliging', value: 'BEV' },
    { name: 'Denekamp', value: 'DEN' },
    { name: 'Energie', value: 'ENE' },
    { name: 'Fleringen', value: 'FLE' },
    { name: 'Groep', value: 'GRP' },
    { name: 'Hardenberg', value: 'HAR' },
    { name: 'Heeten', value: 'HEE' },
    { name: 'Hengelo', value: 'HEN' },
    { name: 'Modulair', value: 'MOD' },
    { name: 'Raalte', value: 'RAA' },
    { name: 'Rheine', value: 'RHE' },
    { name: 'Service', value: 'SRV' },
    { name: 'Zwolle', value: 'ZWO' },
  ],

  features: [
    { feature: FeatureEnum.Inspections, isDisabled: true },
    { feature: FeatureEnum.Orders, isDisabled: true },
    {
      feature: FeatureEnum.Planning,
      flags: { [FeatureFlagEnum.CanTransferEmployees]: true, [FeatureFlagEnum.Viewer]: true },
      metadata: {
        assignableLeaveTypes: [
          AssignmentLeaveTypeEnum.School,
          AssignmentLeaveTypeEnum.Sick,
          AssignmentLeaveTypeEnum.Leave,
          AssignmentLeaveTypeEnum.ParentLeave,
          AssignmentLeaveTypeEnum.Doctor,
          AssignmentLeaveTypeEnum.ReducedWorkingHours,
          AssignmentLeaveTypeEnum.Other,
        ],
        defaultStartOfWorkDay: '08:00',
        defaultEndOfWorkDay: '17:00',
      },
    },
    {
      feature: FeatureEnum.Projects,
      flags: { [FeatureFlagEnum.ProjectTypes]: true },
      metadata: {
        projectTypes: [
          { value: 'P', label: 'Project' },
          { value: 'S', label: 'Servicebon' },
        ],
      },
    },
    { feature: FeatureEnum.Forms, isDisabled: true },
    { feature: FeatureEnum.Education, isDisabled: true },
  ],
};
